import React from "react";

const HeroCard = ({ image, gradient, children }) => {
  // Fallback gradient with a subtle black tint
  const fallbackGradient = "linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))";

  return (
    <div
      className="relative h-fit lg:h-[90vh] bg-cover bg-center flex items-center justify-center"
      style={{
        backgroundImage: `${gradient || fallbackGradient}, url(${image})`,
      }}
    >
      {/* Content */}
      <div className="relative z-10 text-white text-center">{children}</div>
    </div>
  );
};

export default HeroCard;
