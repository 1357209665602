import React from "react";
import HeroCard from "../components/HeroCard";
import docwall from "../images/femal-doc.png";
import Cont from "../components/Cont";
import amb from "../images/ambulance.png";
import check from "../images/healthCheck.png";
import sharedheart from "../images/heart shared.png";
import doc1 from "../images/dc1.png";
import doc2 from "../images/dc2.png";
import doc3 from "../images/dc3.png";

const About = () => {
  return (
    <>
      <div className="relative w-full lg:h-[40vh] h-fit bg-white flex flex-col justify-center px-6 md:px-16 lg:px-32">
        {/* Background Large Text */}
        <div className="absolute top-0 lg:top-0 right-0  lg:right-0 w-[100%] h-full flex justify-center items-center -z-90">
          <h1 className="text-[20vw] md:text-[15vw] lg:text-[12vw] font-bold text-palecolr opacity-30 leading-none">
            About Us
          </h1>
        </div>

        <Cont>
          {/* Foreground Content */}
          <div className="relative">
            <h2 className="text-4xl md:text-5xl lg:text-[64px] font-medium text-purpleTint mb-4 font-roboto">
              About Us
            </h2>
            <p className="text-base md:text-lg text-gray-800 leading-relaxed">
              At Dotty Emergency Assistive Care Inc., we provide electronic
              health recording (EHR) solutions for emergency ambulatory services
              and patient monitoring. Visual analytic tools ensure timely and
              high quality care. Our predictive analytical services will empower
              clients by allowing them to monitor their evolving health and
              treatment patterns, as well as understanding common practices in
              maintaining good health. DottyCare solutions have been developed
              working in collaborative research with hospitals and nursing homes
              in Canada.
            </p>
          </div>
        </Cont>
      </div>

      <HeroCard image={docwall}>
        <div className="">
          <Cont>
            <div className="flex flex-col justify-around p-10">
              <div className="flex justify-center ">
                <p className="text-purpleTint font-roboto text-2xl lg:text-[32px] font-bold mb-10 lg:mb-20">
                  What we Offer
                </p>
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-center w-full gap-12">
                <div className=" shadow-customDrop flex flex-col w-full lg:w-[35%] p-5 lg:p-10 text-center justify-center items-center bg-purpleTint bg-opacity-70 rounded-[40px]">
                  <img
                    alt="ambulance"
                    src={amb}
                    className="w-[80px] lg:w-[150px] h-[80px] lg:h-[150px]"
                  />
                  <h2 className="font-roboto text-l lg:text-[32px] font-normal py-1 lg:py-4">
                    Emergency Services
                  </h2>
                  <p className="font-roboto py-1 lg:py-3 text-[13px] lg:text-[16px]">
                    At Dotty Emergency Assistive Care Inc., we  provide
                    electronic health recording (EHR) solutions for emergency
                    ambulatory services and patient monitoring. Visual analytic
                    tools ensure timely and high quality care. Our predictive
                    analytical services will empower clients by allowing them to
                    monitor their evolving health and treatment patterns, as
                    well as understanding common practices in maintaining good
                    health.  DottyCare solutions have been developed working in
                    collaborative research with hospitals and nursing homes in
                    Canada  
                  </p>
                </div>
                <div className=" shadow-customDrop flex flex-col w-full lg:w-[35%] p-5 lg:p-10 text-center justify-center items-center bg-palePink bg-opacity-70 rounded-[40px]">
                  <img
                    alt="ambulance"
                    src={check}
                    className="w-[80px] lg:w-[150px] h-[80px] lg:h-[150px]"
                  />
                  <h2 className=" text-purpleTint font-roboto text-l lg:text-[32px] font-normal py-1 lg:py-4">
                    Emergency Services
                  </h2>
                  <p className="font-roboto py-1 lg:py-3 text-[13px] lg:text-[16px] text-black">
                    We provide remote web-based solutions that assist in chronic
                    disease management for diseases such as diabetics and
                    monitors the condition of heart, lungs, and other vital
                    signs. The system will provide alert/alarms in the event of
                    an emergency health condition (e.g., a fall among elderly
                    individuals, etc.).
                  </p>
                </div>
              </div>
            </div>
          </Cont>
        </div>
      </HeroCard>

      {/* meet the team */}

      <section className=" py-10 lg:py-20 font-roboto">
        <Cont>
          <div className="flex flex-row justify-center items-center pb-10 gap-4">
            <h2 className="text-center text-3xl font-semibold">
              Meet the team
            </h2>
            <img alt="meet" src={sharedheart} />
          </div>

          <div className="flex flex-col lg:flex-row md:flex-cols gap-8 items-center justify-center h-full">
            {/* Janet Light */}
            <div className="flex flex-col items-center w-full lg:w-7/12">
              <div className="relative w-[60%]">
                <img
                  src={doc1}
                  alt="Janet Light"
                  className="w-full object-cover rounded-lg"
                />
              </div>
              <h3 className=" font-roboto text-center text-purpleTint font-bold mt-4 text-lg lg:text-[32px] py-1 lg:py-3">
                Janet Light
              </h3>
              <div className="p-5 w-[100%] lg:w-[60%] rounded-[24px] shadow-customDrop ">
                <div>
                  <p className="text-justify text-sm text-black px-4 mt-2 font-roboto">
                    Janet Light is the Founder and CEO of Dotty Emergency
                    Assistive Care Inc. Dr. Light has her PhD in Computer
                    Science and currently teaches at the University of New
                    Brunswick, Canada. She manages our team and ensures all
                    backgrounds work together to help support your health goals.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center w-full lg:w-5/12 gap-8">

              {/* Raafey Mohammed */}
              <div className="flex flex-col items-center">
              <div className="relative w-12/12 lg:w-7/12">
                <div className="flex justify-center items-center">
                  
                  <img
                    src={doc2} // Replace with actual image
                    alt="Raafey Mohammed"
                    className="w-8/12 lg:w-full object-cover rounded-lg"
                  />
                  </div>
                  <h3 className=" font-roboto text-center text-purpleTint font-bold mt-4 text-lg lg:text-[32px] py-1 lg:py-3">
                    Raafey Mohammed
                  </h3>
                  <div className="p-3 w-full lg:w-full rounded-[24px] shadow-customDrop ">
                    <p className="text-justify text-sm text-black px-1 mt-2 font-roboto">
                      Er. Raafey Mohammad is an Executive Director in Dotty
                      Emergency Assistive Care Inc. Raafey has his Masters in
                      Computer Science from University of New Brunswick. He has
                      several years of experience in Database management and
                      analytic data Services
                    </p>
                  </div>
                </div>
              </div>

              {/* Jong Kyou Kim */}

              <div className="flex flex-col items-center">
                <div className="relative w-12/12 lg:w-7/12">
                <div className="flex justify-center items-center">
                    
                  <img
                    src={doc3} // Replace with actual image
                    alt="Jong Kyou Kim"
                    className="w-7/12 lg:w-full object-cover rounded-lg"
                  />
                  
                </div>
                  <h3 className=" font-roboto text-center text-purpleTint font-bold mt-4 text-lg lg:text-[32px] py-1 lg:py-3">
                  Jong Kyou Kim
                  </h3>
                  <div className="p-5 w-full lg:w-full rounded-[24px] shadow-customDrop ">
                    <p className="text-justify text-sm text-black px-1 mt-2 font-roboto">
                    Dr. Kim is a Director in Dotty Emergency Assistive Care Inc.
                  Dr. Kim is a Faculty in Computer Science at the University of
                  New Brunswick. An experienced software developer with Web
                  Applications and a visionary of future technology systems.
                </p>
                  </div>
                </div>
              </div>



              
            </div>
          </div>
        </Cont>
      </section>
    </>
  );
};

export default About;
