import React from "react";
import { useLocation, Link } from "react-router-dom";

import styles from "../global.module.css";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Button,
} from "@nextui-org/react";
import "../../src/App.css";
import "../../src/index.css";
import logo from "../images/logo.png";

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const menuItems = [
    { text: "Home", to: "/home" },
    { text: "About", to: "/about" },
    { text: "Service", to: "/service" },
    { text: "Contact Us", to: "/contact" },
    { text: "SignIn", to: "/login" },
    { text: "Create Account", to: "/signup" },
  ];
  const location = useLocation(); // Get current route

  const links = [
    { label: "Home", to: "/" },
    { label: "About Us", to: "/about" },
    { label: "Services", to: "/services" },
    { label: "Contact Us", to: "/contact" },
  ];

  return (
    <div className="flex items-center justify-center bg-white">
      <Navbar
        isBordered="true"
        position="sticky"
        onMenuOpenChange={setIsMenuOpen}
        className=" inner_tag font-inter h-24"
      >
        <NavbarContent className={styles.navlogo}>
          <NavbarBrand>
            <img src={logo} alt="logo" />
            {/* <p className="font-bold text-inherit"></p> */}
          </NavbarBrand>
        </NavbarContent>

        {/* Navbar Links */}
        <NavbarContent
          justify="center"
          className={`${styles.navlinks} hidden sm:flex gap-14 font-semibold font-inter text-base`}
        >
          {links.map((link) => (
            <NavbarItem key={link.to}>
              <Link
                to={link.to}
                className={`text-black hover:text-purpleTint transition-colors duration-300 ${
                  location.pathname === link.to
                    ? "text-purpleTint font-bold"
                    : ""
                }`}
              >
                {link.label}
              </Link>
            </NavbarItem>
          ))}
        </NavbarContent>

        <NavbarContent
          justify="end"
          className="gap-10 font-semibold font-inter text-base hidden lg:flex"
        >
          <NavbarItem className=" transition-all">
            <Link to="/login" className={styles.textLink}>
              Sign in
            </Link>
          </NavbarItem>
          <NavbarItem className="font-semibold font-inter text-base">
            <Button
              as={Link}
              color="primary"
              to="/signup"
              variant="flat"
              className={styles.textbut}
            >
              Create Account
            </Button>
          </NavbarItem>
        </NavbarContent>

        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />

        <NavbarMenu>
          {menuItems.map((item, index) => (
            <NavbarMenuItem key={`${item.text}-${index}`}>
              <Link
                color={
                  index === 2
                    ? "primary"
                    : index === menuItems.length - 1
                    ? "danger"
                    : "foreground"
                }
                className="w-full"
                href="#"
                size="lg"
                to={item.to}
              >
                {item.text}
              </Link>
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </Navbar>
    </div>
  );
}
