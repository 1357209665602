import React from "react";
import HeroCard from "../components/HeroCard";
import heroImg from "../images/emergency.png";
import Cont from "../components/Cont";
import check from "../images/healthCheck.png";
import doctor from "../images/doctor.png";
import amb from "../images/ambulance.png";
import seth from "../images/seth.png";
import heart from "../images/heart.png";
import chat from "../images/chat.png";
import pica from "../images/pic1.png";
import picb from "../images/pic2.png";
import picc from "../images/pic3.png";
import stars from "../images/stars.png";

const HomePage = () => {
  return (
    <div>
      <HeroCard
        image={heroImg}
        gradient="linear-gradient(90deg, rgba(42, 1, 35, 0.3) 23%, rgba(144, 2, 118, 0.3) 38%, rgba(144, 2, 118, 0.5) 73%)"
      >
        <Cont>
          <div className="flex flex-row justify-center lg:w-7/12 justify-self-end gap-4 py-[40px] lg:py-0">
            <div className="hidden lg:flex flex-row gap-3">
              <div class="h-full w-[1px] bg-white"></div>
              <div class="h-full w-[1px] bg-white"></div>
            </div>
            <div className="w-full text-left leading-none font-bold">
              <div className="font-roboto text-6xl lg:text-[79px] text-white">
                <p>
                  Live
                  <span className="text-yellowTint">
                    {" "}
                    well & safe
                  </span> with <span className="text-yellowTint">
                    peace{" "}
                  </span>{" "}
                  of mind{" "}
                </p>
              </div>
              <div>
                <p className="py-8 text-xl lg:text-[23px] md:text-16px] font-roboto font-normal">
                  DottyCare is here to help you get quick and optimum care
                  immediately in an emergency situation
                </p>
              </div>
              <div className="pt-4">
                <button className="bg-white font-extrabold px-4 py-5 hover:bg-purpleTint transition-all delay-75 text-purpleTint hover:text-white rounded-lg border-purpleTint font-inter text-[20px]">
                  {" "}
                  DottyCare Apps
                </button>
              </div>
            </div>
          </div>
        </Cont>
      </HeroCard>

      <div className="flex justify-center align-middle items-center">
        <Cont>
          <div className="flex flex-col lg:flex-row justify-between py-[50px] px-0">
            <div className="flex flex-col w-full lg:w-8/12 order-2 font-roboto justify-center">
              <h2 className="text-2xl lg:text-[45px] font-bold">
                Keeping Your Health in Mind
              </h2>
              <p className="text-[16px] font-normal py-4">
                Call us to discuss customized solutions for your real-time
                pre-hospital ambulance data collection needs for an ambulance
                call (such as 911 call in America, 108 Call in India, private
                and hospital ambulances).
              </p>
            </div>
            <div className="flex w-full lg:w-2/12 justify-center order-1 lg:order-2">
              <img src={check} />
            </div>
          </div>
        </Cont>
      </div>

      <div className="flex justify-center align-middle items-center ">
        <Cont>
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex w-full lg:w-6/12 order-2 lg:order-1">
              <div className="flex flex-col gap-4">
                <div className="p-10 w-[100%] lg:w-[90%] rounded-[59px] shadow-customDrop ">
                  <div className="flex flex-row items-center">
                    <div className="pr-0 lg:pr-5">
                      <img src={amb} />
                    </div>
                    <div>
                      <p className="font-roboto text-2xl lg:text-[32px] text-purpleTint  ">
                        Our mission
                      </p>
                    </div>
                  </div>
                  <div>
                    <p>
                      In emergency situations, our system provides timely access
                      to your vital patient information and other critical
                      health care data required for better treatments. This
                      system also assists caregivers in exchange of information
                      with the nearest hospitals during an emergency, to provide
                      life-impacting treatment during the first golden hour.  
                    </p>
                  </div>
                </div>
                <div className="p-10 w-[100%] lg:w-[90%] rounded-[59px] shadow-customDrop ">
                  <div className="flex flex-row items-center">
                    <div className="pr-0 lg:pr-5">
                      <img src={seth} />
                    </div>
                    <div>
                      <p className="font-roboto text-2xl lg:text-[32px] text-purpleTint  ">
                        Experience and Professionalism
                      </p>
                    </div>
                  </div>
                  <div>
                    <p>
                      With years of R&D experience in IT services for
                      healthcare, our team will provide support to you to
                       access quickly medical help. We understand the importance
                      of educating you on the most effective ways to keep you
                      safe during emergency, so that you can access medical help
                      quickly. 
                    </p>
                  </div>
                </div>
                <div className="p-10 w-[100%] lg:w-[90%] rounded-[59px] shadow-customDrop ">
                  <div className="flex flex-row items-center">
                    <div className="pr-0 lg:pr-5">
                      <img src={heart} />
                    </div>
                    <div>
                      <p className="font-roboto text-2xl lg:text-[32px] text-purpleTint  ">
                        We Care
                      </p>
                    </div>
                  </div>
                  <div>
                    <p>
                      We care that you can reach a hospital and medical help as
                      soon as possible. We also work to maximize your prevention
                      strategies. We strive to help you improve your quality of
                      life and care, by providing access to medical history for
                      quicker diagnosis and efficient care. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex order-1 lg:order-2">
              <img src={doctor} />
            </div>
          </div>
        </Cont>
      </div>

      <div className="py-[80px] lg:py-[100px] flex justify-center align-middle items-center">
        <Cont>
          <div className="flex flex-col px-10">
            <div className="flex flex-row w-full font-roboto justify-center lg:justify-start items-center ">
              <h2 className="text-2xl lg:text-[45px] font-normal">
                Testimonials
              </h2>
              <img
                src={chat}
                className="w-[90px] h-[90px] lg:w-[150px] lg:h-[150px] pl-[20px]"
              />
            </div>
            <div className=" flex justify-center py-[90px] mt-[40px]">
              <div className="justify-center flex flex-col lg:flex-row gap-14 ">
                          
              <div className="mb-[50px] lg:mb-[0px] shadow-customDrop1 text-white text-center bg-purplebox rounded-[24px] w-full lg:w-3/12 p-10 shadow-customDrop">
                <div className="mt-[-60%]">
                  <img src={pica} />
                </div>
                <div className=" flex flex-col justify-center">
                  <p className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad
                  </p>
                  <p className="pt-[15px] font-medium">Winifred clay</p>
                  <div className="flex justify-center pt-[15px]">
                  <img src={stars}  className="h-5 w-auto"/>
                  </div>
                </div>
              </div>
              <div className="mb-[50px] lg:mb-[0px] shadow-customDrop1 text-white text-center bg-purplebox rounded-[24px] w-full lg:w-3/12 p-10 shadow-customDrop">
                <div className="mt-[-60%]">
                  <img src={picb} />
                </div>
                <div className=" flex flex-col justify-center">
                  <p className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad
                  </p>
                  <p className="pt-[15px] font-medium">Alex Maxwell</p>
                  <div className="flex justify-center pt-[15px]">
                  <img src={stars}  className="h-5 w-auto"/>
                  </div>
                </div>
              </div>  
              <div className="shadow-customDrop1 text-white text-center bg-purplebox rounded-[24px] w-full lg:w-3/12 p-10 shadow-customDrop">
                <div className="mt-[-60%]">
                  <img src={picc} />
                </div>
                <div className=" flex flex-col justify-center">
                  <p className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad
                  </p>
                  <p className="pt-[15px] font-medium">Juliet Stone</p>
                  <div className="flex justify-center pt-[15px]">
                  <img src={stars}  className="h-5 w-auto"/>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </Cont>
      </div>
    </div>
  );
};

export default HomePage;
