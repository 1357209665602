import React from "react";
import Cont from "../components/Cont";
import pinkPhone from "../images/pinkphone.png";
import HeroCard from "../components/HeroCard";
import contactback from "../images/contactbg.png";

const Contact = () => {
  return (
    <>
      <Cont>
        <section className="flex flex-col lg:flex-row items-center justify-center text-center py-12 bg-white font-roboto">
          {/* Heading */}
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl md:text-5xl font-bold text-black mb-4">
              Contact us
            </h1>

            {/* Subtitle */}
            <p className="text-sm md:text-base text-black mb-8">
              Our Wellness in Photos: A Picture is Worth a Thousand Words
            </p>
          </div>
          {/* Image */}
          <div className="mt-4">
            <img src={pinkPhone} alt="Pink Phone" className="w-32 md:w-40" />
          </div>
        </section>
      </Cont>

      <HeroCard
        image={contactback}
        gradient={
          "linear-gradient(to right, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4))"
        }
      />

      <section className="bg-purpleTint text-white p-6 md:p-12 text-center">
        <Cont>
          {/* Contact Information */}
          <div className="flex justify-center items-center  py-8">
            <div className="mb-8 w-full lg:w-10/12 font-roboto">
              <h1 className="text-lg md:text-2xl lg:text-[32px] font-light mb-2 lg:mb-8">
                Dotty Emergency Assistive Care Inc. 290 Crown Street, Saint
                John, NB, Canada. Postal Code: E2L2Y4{" "}
              </h1>
              <p className="text-sm md:text-base mb-8">
                Please contact us directly with any questions, comments, or
                scheduling inquiries you may have. {" "}
              </p>
              <p className="text-xs md:text-sm lg:text-[32px] mt-2">
                Dotty Emergency Assistive Care Inc.{" "}
              </p>
            </div>
          </div>
        </Cont>
      </section>

      <section className="text-white p-6 md:p-12 text-center font-roboto">
        <Cont>
          {/* Hours Section */}
          <div className="bg-white text-purple-800 rounded-md  p-6 max-w-lg mx-auto">
            <h2 className="text-purpleTint text-xl lg:text-[32px] font-bold mb-4">
              Hours
            </h2>

            {/* Days and Time */}
            <div className="space-y-4">
              {/* Monday - Friday */}
              <div className="flex items-center justify-between bg-palecolr rounded-[16px] overflow-hidden">
                <div className="bg-purpleTint text-white lg:text-[30px] px-6 py-2 font-normal rounded-[16px]">
                  MON - FRI
                </div>
                <div className="text-black px-6 py-2 lg:text-[30px]">
                  8AM–8PM
                </div>
              </div>

              {/* Saturday - Sunday */}
              <div className="flex items-center justify-between bg-palecolr rounded-[16px] overflow-hidden">
                <div className="bg-purpleTint text-white px-6 py-2 lg:text-[30px] font-normal rounded-[16px]">
                  SAT - SUN
                </div>
                <div className="text-black px-6 py-2 lg:text-[30px]">
                  8AM–6PM
                </div>
              </div>
            </div>

            {/* Emergency Hours */}
            <div className="mt-6 text-lg font-semibold text-black">
              Emergency Service:{" "}
              <span className="text-purpleTint font-bold">24/7</span>
            </div>

            {/* Message Button */}
            <div className="mt-6">
              <button className="bg-purpleTint text-white px-6 py-3 rounded-[8px] hover:bg-palecolr hover:text-black transition duration-300">
                Send a message
              </button>
            </div>
          </div>
        </Cont>
      </section>
    </>
  );
};

export default Contact;
