import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";

import '@fontsource/roboto'; // Defaults to 400 weight
import Services from "./Pages/Services";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import SignUp from "./Pages/SignUp";
import LogIn from "./Pages/LogIn";



function App() {
  return (
    <>
    
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/services" element={<Services />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<LogIn />} />


    </Routes>
    </>
  );
}

export default App;
