import React from "react";
import ambBack from "../images/amb2.png";
import crest from "../images/crest.png";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <div className="flex items-center justify-center min-h-[90vh]">
      {/* Card Container */}
      <div
        className="backdrop-blur-md rounded-[47px] p-8 max-w-[90%] lg:max-w-[40%] shadow-lg bg-cover bg-center "
        style={{
          backgroundImage: `linear-gradient(rgba(121,0,128,0.4), rgba(109,0,128,0.4)), url(${ambBack})`,
        }}
      >
        {/* Logo */}
        <div className="flex justify-center mb-4">
          <img src={crest} alt="Shield Icon" className="w-15 h-15" />
        </div>

        {/* Welcome Text */}
        <h2 className="text-2xl text-center text-white font-semibold mb-6">
          Welcome
        </h2>

        {/* Form */}
        <form className="space-y-4 font-roboto px-[1px] lg:px-[40px] justify-center items-center ">
          <input
            type="text"
            placeholder="First Name"
            className="w-full p-3 rounded-md border-none focus:outline-none focus:ring-2 focus:ring-purple-300"
          />
          <input
            type="text"
            placeholder="Last Name"
            className="w-full p-3 rounded-md border-none focus:outline-none focus:ring-2 focus:ring-purple-300"
          />
          <input
            type="email"
            placeholder="Email Address"
            className="w-full p-3 rounded-md border-none focus:outline-none focus:ring-2 focus:ring-purple-300"
            required
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" // Optional: Custom email pattern
            title="Please enter a valid email address."
          />
          <input
            type="tel"
            placeholder="Phone number"
            className="w-full p-3 rounded-md border-none focus:outline-none focus:ring-2 focus:ring-purple-300"
            required
            pattern="(\(\d{3}\)\s?)?\d{3}[-.\s]?\d{4}" // Regex for phone number format
            title="Please enter a valid phone number (e.g., (123) 456-7890)"
          />
          {/* Submit Button */}
          <div className="flex justify-center py-4">
            <button
              type="submit"
              className=" w-[60%] lg:w-[40%] bg-butbox text-purpleTint font-inter text-[16px] font-semibold py-3 rounded-[8px] hover:bg-purpleTint hover:text-butbox transition"
            
            >
              Create Account
            </button>
          </div>
        </form>

        {/* Login Redirect */}
        <p className="text-center text-white text-sm mt-4">
          Already have an account?{" "}
          <Link to="/login"className="font-semibold cursor-pointer">Login</Link>
        </p>

        {/* Disclaimer */}
        <p className="text-center text-white text-xs mt-2">
          By creating an account, you may receive newsletters or promotions.
        </p>
      </div>
    </div>
  );
};

export default SignUp;
