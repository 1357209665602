import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import "./index.css";
import App from "./App";
import { NextUIProvider } from "@nextui-org/react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NextUIProvider>
    <React.StrictMode>
      <BrowserRouter>
      <Nav />
      <App />
      <Footer/>
      </BrowserRouter>
    </React.StrictMode>
  </NextUIProvider>
);
