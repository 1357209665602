import React from "react";
import HeroCard from "../components/HeroCard";
import ambHero from "../images/ambhero.png";
import Cont from "../components/Cont";
import tyme from "../images/247.png";
import supp from "../images/support.png";
import coumm from "../images/community.png";

const Services = () => {
    const rows = Array(7).fill(null); // 7 rows of placeholders

  return (
    <>
      <HeroCard
        image={ambHero}
        gradient="linear-gradient(to right, rgba(135, 0, 110, 0.7), rgba(135, 0, 110, 0.5))"
      >
        <Cont>
          <div className="flex justify-center flex-col items-center py-10 lg:py-0">
            <p className="text-2xl lg:text-[32px] font-roboto font-bold mb-[10px] lg:mb-[50px]">
              {" "}
              Our Services
            </p>
            <div className="flex flex-col w-full lg:w-4/12 items-center">
              <div className="flex flex-row justify-center items-center mb-[20px]">
                <img
                  src={tyme}
                  alt="247"
                  className="mr-[-30px] lg:mr-[-40px] z-50 w-[120px] lg:w-[150px]"
                />
                <div className="bg-white">
                  <p className="py-[5px] lg:py-[10px] px-[20px] lg:px-[30px] font-roboto text-purpleTint text-2xl lg:text-[32px] font-normal">
                    24 Hour Service
                  </p>
                </div>
              </div>
              <p className="font-roboto font-medium text-l">
                We know that your medical needs don't stop when office hours are
                over. Using our help line and our email service, you can get a
                message to your health team when it is convenient for you.
              </p>
            </div>

            <div className="flex flex-col lg:flex-row justify-around mt-2 lg:mt-20">
              <div className="flex flex-col w-full lg:w-4/12 items-center">
                <div className="flex flex-row justify-center items-center mb-[20px]">
                  <img
                    src={supp}
                    alt="support"
                    className="mr-[-10px] lg:mr-[-20px] z-50 w-[100px] lg:w-[130px]"
                  />
                  <div className="bg-white ">
                    <p className="py-[5px] lg:py-[10px] px-[20px] lg:px-[30px] font-roboto text-purpleTint text-2xl lg:text-[32px] font-normal">
                      Wellness Support
                    </p>
                  </div>
                </div>
                <p className="font-roboto font-medium text-l">
                  Our team will support you in building a healthier you. No
                  matter what your health needs are, having a team support you
                  will keep you on the path to meeting them. We work together to
                  connect you with the services you need.
                </p>
              </div>
              <div className="flex flex-col w-full lg:w-4/12 items-center">
                <div className="flex flex-row justify-center items-center mb-[20px]">
                  <img
                    src={coumm}
                    alt="community"
                    className="mr-[-10px] lg:mr-[-20px] z-50 w-[100px] lg:w-[130px] h-auto"
                  />
                  <div className="bg-white ">
                    <p className="py-[5px] lg:py-[10px] px-[20px] lg:px-[30px] font-roboto text-purpleTint text-2xl lg:text-[32px] font-normal">
                      Healthy Community
                    </p>
                  </div>
                </div>
                <p className="font-roboto font-medium text-l">
                  When you choose us, you join a community. We work not just
                  with you but with other members of our community to build a
                  network of people working together for a healthier world. 
                </p>
              </div>
            </div>
          </div>
        </Cont>
      </HeroCard>

      {/* Appointments  */}

      <div className="py-[40px]">
        <Cont>
          <div className="flex flex-col items-center p-6">
            <h2 className="text-2xl font-bold mb-6">Appointments</h2>
            <div className="grid grid-cols-2 gap-4 w-3/4">
              {rows.map((_, index) => (
                <React.Fragment key={index}>
                  <div className="h-12 bg-gray-300 rounded"></div>
                  <div className="h-12 bg-gray-300 rounded"></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Cont>
      </div>
    </>
  );
};

export default Services;
