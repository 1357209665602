import React from "react";

const Cont = ({ children }) => {
  return (
    <div className="max-w-[1440px] w-full px-4 sm:px-8 lg:px-10 mx-auto">
      {children}
    </div>
  );
};

export default Cont;
