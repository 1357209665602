import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-palePink">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Section */}
        <div className="text-purpleTint font-roboto lg:text-[23px] flex items-center">
          <ul className="space-y-4">
            <li className="font-normal">
              <Link to="/">Home</Link>
            </li>
            <li className="font-normal">
              <Link to="/about">About</Link>
            </li>
            <li className="font-normal">
              <Link to="/services">Services</Link>
            </li>
            <li className="font-normal">
              <Link to="/contact">Contact Us</Link>
            </li>
            <li className="font-normal">
              <Link to="/login">Account</Link>
            </li>
          </ul>
        </div>

        {/* Right Section */}
        <div className="text-center lg:text-right text-gray-700 text-sm flex flex-col gap-[5px]">
          <div className="text-center lg:text-right">
            <h3 className="text-lg lg:text-[32px] lg:font-roboto font-bold text-purpleTint py-5">
              Subscribe to our Newsletter
            </h3>
            <form className="mt-4 w-full flex flex-col justify-items-end space-y-2 lg:space-y-5 items-end">
              <input
                type="email"
                placeholder="Email Address"
                className="w-full h-[50px] max-w-md px-4 py-2 border border-gray-300 rounded-md w-[80%] justify-start"
              />
              <button className="w-fit font-inter font-medium text-[16px] px-[35px] py-[15px] bg-purpleTint text-white rounded-md hover:bg-purplebox transition">
                Subscribe
              </button>
            </form>
          </div>
          <p className="pt-1 lg:pt-10 font-roboto font-normal text-[16px]">
            Dotty Emergency Assistive Care Inc. 290 Crown Street, Saint John,
            NB, Canada.
          </p>
          <p className="font-roboto font-normal text-[16px]">
            Postal Code: E2L2Y4
          </p>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="bg-gray-100 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row justify-between items-center">
          <p className="text-sm text-gray-500">
            © 2024 Dottycare. All rights reserved.
          </p>
          <div className="flex space-x-4 mt-4 lg:mt-0">
            <a href="#" className="text-gray-500 hover:text-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                {/* Example icon */}
                <path d="M..."></path>
              </svg>
            </a>
            {/* Add more icons here */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
